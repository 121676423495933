<template>
  <div>
    <slot />
  </div>
</template>

<style scoped>
div {
  min-height: 100vh;
}
</style>
